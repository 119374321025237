export function getCookie(key: string) {
    if (typeof document === 'undefined') return ''
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
}

export function setCookie(key: string, value: string) {
    if (typeof document === 'undefined') return

    document.cookie = `${key}=${value}`;
}

export function removeCookie(key: string) {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}
