'use client';
import {create} from "zustand";
import { immer } from 'zustand/middleware/immer'
import SalesEnum from "@/app/enums/Health/SalesEnum";
import ISale from "@/@types/health/sale";
import {getCookie, removeCookie, setCookie} from "@/app/utils/client-cookies";
import OpenHourToolkit from "@/app/utils/open-hour-toolkit";
import {format} from "date-fns";
import DateEnum from "@/app/enums/Date";
import {Products} from "@/app/enums/Products";
import {getRandomValueFromDistribution} from "@/app/utils/probability";

export const HEALTH_EXPERT_ID_COOKIE = 'rm_health_expert_id';

const BASE_API = process.env.NEXT_PUBLIC_RM_BASE_API + '/api';
const BASE_WEBSITE = process.env.NEXT_PUBLIC_RM_BASE_API;

const getSaleFromCookie = () => {
    const sales = SalesEnum.reviews
    let expertIdCookie = getCookie(HEALTH_EXPERT_ID_COOKIE) as string | undefined;

    if (expertIdCookie && !sales.find(sale => sale.expert_id === parseInt(expertIdCookie as string))) {
        expertIdCookie = undefined;
    }

    return expertIdCookie;
}

const getRandomSaleId = () => {
    const email = getRandomValueFromDistribution(JSON.parse(process.env.NEXT_PUBLIC_HEALTH_SALE_DISTRIBUTION as string), 'email');
    const saleId = SalesEnum.ID_MAP_BY_MAIL[email] || SalesEnum.SALE_HEALTH.expert_id
    return saleId || SalesEnum.SALE_HEALTH.expert_id
}

const loadSale = (forcedSaleId?: number) => {
    const sales = SalesEnum.reviews
    let currentSaleId: ISale["expert_id"] = forcedSaleId || getSaleFromCookie();

    if (!currentSaleId) {
        currentSaleId = getRandomSaleId();
        if (currentSaleId) setCookie(HEALTH_EXPERT_ID_COOKIE, currentSaleId.toString())
    }

    const matchedCurrentSale = sales.find(sale => sale.expert_id === parseInt(currentSaleId as string))
    if (matchedCurrentSale) {
        return matchedCurrentSale
    }
}

export interface HealthStoreState {
    token?: string;
    currentQuote: {};
    isInitialLoading: boolean;
    callbackForm: {
        lastName?: string;
        firstName?: string;
        email?: string;
        phone?: string;
        date?: string;
        hours?: number;
    },
    landingForm: {
        familyType: number | null;
    },
    csp: string | null,
    isCallbackFormLoading: boolean,
    currentSale: ISale,
    getSortedSalesList: Function,
    setProperty: Function,
    setToken: Function,
    loadQuote: Function,
    isCsp: Function,
    validateLanding: Function,
    sendValidateLandingRequest: Function,
    getUrls: Function,
    askForAppointment: Function,
    createQuote: Function,
}

export function getCsp() {
    if (typeof window === 'undefined') return null
    return new URLSearchParams(window.location.search)?.get("csp")
}

export const useHealthStore = create<HealthStoreState>()(
    immer((set, get) => {
        return {
            token: undefined,
            isInitialLoading: true,
            csp: getCsp(),
            landingForm: {
                familyType: null,
            },
            callbackForm: {
                lastName: undefined,
                firstName: undefined,
                email: undefined,
                phone: undefined,
                date: new Date().toISOString().split('T')[0],
                hours: OpenHourToolkit.getHoursForDay(new Date().toISOString().split('T')[0])[0]?.value,
            },
            isCallbackFormLoading: false,
            currentQuote: {},
            currentSale: loadSale(),
            createQuote: async (askForAppointment = false) => {
                const { currentSale, token, callbackForm } = get();

                const formData = {
                    token: token as string,
                    abTestReviewsVersion: '',
                    assignToExpert: currentSale.expert_id?.toString() || '',
                    csp: getCsp() || '',
                    partnerSource: '',
                    email: callbackForm.email as string,
                }

                set(state => {
                    state.isCallbackFormLoading = true
                })

                return fetch(`${BASE_API}/health/validate-landing`, {
                    body: JSON.stringify(formData),
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(async (response) => {
                        const data = await response.json()
                        set(state => {
                            state.token = data.token
                        })

                        if (askForAppointment) {
                            return get().askForAppointment()
                        }
                    })
                    .finally(() => {
                        set(state => {
                            state.isCallbackFormLoading = false
                        })
                    })
            },
            askForAppointment: async () => {
                const { callbackForm, token } = get();

                const phoneToPlus33 = callbackForm.phone?.replace(/^0/, '+33')

                const formData = {
                    token: token as string,
                    appointment_date: format(new Date(callbackForm.date as string), DateEnum.EUROPEAN_FORMAT),
                    appointment_time: (callbackForm.hours || '').toString(),
                    first_name: callbackForm.firstName as string,
                    last_name: callbackForm.lastName as string,
                    phone: phoneToPlus33 as string,
                    product_type: Products.HEALTH,
                    type: 11,
                    from_link: window.location.href,
                }

                set(state => {
                    state.isCallbackFormLoading = true
                })

                await fetch(`${BASE_API}/health/callback`, {
                    body: JSON.stringify(formData),
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })

                const response = await fetch(`${BASE_API}/common/callback`, {
                    body: JSON.stringify(formData),
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })

                set(state => {
                    state.isCallbackFormLoading = false
                })

                return response.status === 200
            },
            validateLanding: (familyType: string | null = null ) => {
                set(state => {
                    state.landingForm.familyType = familyType !== null ? parseInt(familyType) : null
                })

                const { currentSale, token, sendValidateLandingRequest } = get();
                const formData = {
                    abTestReviewsVersion: null,
                    token,
                    assignToExpert: currentSale.expert_id,
                    csp: getCsp(),
                    partnerSource: null,
                }
                const storedTokenInCookie = getCookie('health_quote_stored_token')
                const isUserCRM = window && window.role && window.role.includes('ROLE_MUTUELLE_SANTE')

                if (!token && storedTokenInCookie && !isUserCRM) {
                    fetch(`${BASE_API}/health/check-token-from-cookie/${storedTokenInCookie}`)
                        .then(async (response) => {
                            const data = await response.json()
                            if (data) {
                                sendValidateLandingRequest({ ...formData, token: data })
                            } else {
                                removeCookie(HEALTH_EXPERT_ID_COOKIE)
                                sendValidateLandingRequest({ ...formData, token: null })
                            }
                        })
                        .catch(() => {
                            removeCookie(HEALTH_EXPERT_ID_COOKIE)
                            sendValidateLandingRequest(formData)
                        })
                } else {
                    sendValidateLandingRequest(formData)
                }
            },
            getUrls: (token?: string) => ({
                comparatorForm: `${BASE_WEBSITE}/formulaire-mutuelle-sante/${token}/1?familyType=${get().landingForm.familyType}&comesFromLanding=true`,
            }),
            sendValidateLandingRequest: (formData: any) => {
                fetch(`${BASE_API}/health/validate-landing`, {
                    method: 'POST',
                    body: JSON.stringify(formData),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(async (response) => {
                        const data = await response.json()
                        get().setToken(data.token)
                        setCookie('health_quote_stored_token', data.token)
                        window.history.pushState({}, '' , `/comparateur-mutuelle-sante/${data.token}`)
                        const url = get().getUrls(data.token).comparatorForm

                        window.location.href = url
                    })
                    .catch(() => {
                        // this.loading = false
                        // this.$toasted.error('Une erreur est survenue, veuillez réessayer plus tard')
                    })
                    .finally(() => {
                        // this.loading = false
                    })

            },
            getSortedSalesList: () => {
                return SalesEnum.reviews.filter(sale => sale.expert_id !== 'EQUIPE_MS')
                    .sort((prevSale, nextSale) => prevSale.expert_id === get().currentSale?.expert_id ? -1 : 1)
            },
            isCsp: (cspTested: 'senior' | 'tns') => {
                return get().csp === cspTested
            },
            setProperty: (property: keyof HealthStoreState['callbackForm'], value: any) => set((state) => { state.callbackForm[property] = value}),
            setToken: (token: string) => set((state) => { state.token = token }),
            loadQuote: (token: string) => {
                set((state) => { state.token = token; state.isInitialLoading = true })
                fetch(`${BASE_API}/health/token/${token}`)
                    .then(async (response) => {
                        const data = await response.json()
                        const pi = data.main_contractor?.prospect_info
                        set((state) => {
                            state.currentSale = loadSale(data.sales_person?.id) as ISale
                            state.currentQuote = data
                            state.callbackForm.firstName = pi.first_name
                            state.callbackForm.lastName = pi.last_name
                            // phone number from 0643443515 to 06 43 44 35 15
                            state.callbackForm.phone = pi.phone.length > 1 ? pi.phone.replace('+33', '0').replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5') : pi.phone
                            state.callbackForm.email = pi.prospect.email
                        })
                    })
                    .catch((er) => {
                    })
                    .finally(() => {
                        set((state) => { state.isInitialLoading = false; })
                    })
            },
        } as HealthStoreState
    })
)
