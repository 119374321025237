interface IDIstributionItem {
    [key: string]: any;
    percentage: number;
}

export const getRandomValueFromDistribution = (distribution: IDIstributionItem[], valueKey = 'value') => {
    const totalPercentage = distribution.reduce((sum, distributionItem) => sum + distributionItem.percentage, 0);
    const randomNumber = Math.floor(Math.random() * totalPercentage) + 1;

    let start = 1;
    for (const item of distribution) {
        if (randomNumber >= start && randomNumber <= start + item.percentage) {
            return item[valueKey]
        }

        start += item.percentage
    }
}
