const OpenHourToolkit = {
    START_HOUR: 9,
    END_HOUR: 18,
    formatHours: (startingHour: number, endingHour: number) => {
        const _hours = [];
        for (let i = startingHour; i < endingHour; i++) {
            _hours.push({
                label: `${i}h - ${i+1}h`,
                value: i,
            })
        }

        return _hours;
    },

    isToday: (date: string) => new Date().toISOString().split('T')[0] === date,

    isHourAbove17: (date = new Date().toISOString()) => new Date(date).getHours() + 1 > 17,

    getHoursForDay: (date: string) => {
        const { START_HOUR, END_HOUR } = OpenHourToolkit
        let min = START_HOUR

        if (OpenHourToolkit.isToday(date)) min = new Date().getHours() + 1

        if (min >= END_HOUR) min = OpenHourToolkit.START_HOUR

        return OpenHourToolkit.formatHours(min, END_HOUR)
    },
    CLOSED_DAYS: [0, 6], // 0 = Sunday, 6 = Saturday
    getNextOpenDate: (date = new Date().toISOString().split('T')[0]): string => {
        const day = new Date(date).getDay();
        const time = new Date().getHours() + 1
        const isToday = OpenHourToolkit.isToday(date)

        if (OpenHourToolkit.CLOSED_DAYS.includes(day) || (isToday && time > 17)) {
            const newDate = new Date(date)
            const nextOpenDay = newDate.setDate(newDate.getDate() + 1)

            return OpenHourToolkit.getNextOpenDate(new Date(nextOpenDay).toISOString().split('T')[0])
        }

        return date
    },
}

export default OpenHourToolkit
