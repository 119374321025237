import ISale from "@/@types/health/sale";

export default class SalesEnum {
    static SALE_GUILLAUME = {
            expert_id: 76,
            image: 'GUILLAUME_SPORTIELLO',
            name: 'Guillaume S',
            satisfied_clients: 1769,
            clientsReviews: 72,
            seniority: 4,
            reviews: [
                {
                    stars: 5,
                    reviewerName: 'Christine T.',
                    date: 'Il y a 3 semaines',
                    comment: 'Très bon service, rapide, efficace et très gentil. Un très grand merci à Guillaume !',
                },
                {
                    stars: 5,
                    reviewerName: 'Juliette V.',
                    date: 'Il y a 1 mois',
                    comment: `Très agréablement surprise par la qualité de service. Un conseiller (M.Sportiello) d'une
                    grande gentiellesse, très humain, qui a non seulement trouvé l'assurance qui me convenait le mieux,
                     mais qui a aussi pris le temps de m'expliquer tous les termes techniques nécessaires à la
                     compréhension du contrat. Un grand merci !`,
                },
                {
                    stars: 5,
                    reviewerName: 'Nathalie M.',
                    date: 'Il y a 4 jours',
                    comment: `Comparatif très utile, simple et efficace. Réactivité immédiate pour plus d'informations
                    au téléphone. Merci Guillaume pour vos conseils et votre gentillesse.`,
                },
            ],
        }

    static SALE_ALDRIC = {
            expert_id: 84,
            image: 'ALDRIC_BELHADJ',
            name: 'Aldric B',
            satisfied_clients: 1407,
            clientsReviews: 67,
            seniority: 3,
            reviews: [
                {
                    stars: 5,
                    reviewerName: 'Hakim C.',
                    date: 'Il y a 1 mois',
                    comment: `J'ai trouvé avec Aldric ma mutuelle et je tiens à la remercier pour ses conseils et son
                     suivi. Je recommande !`,
                },
                {
                    stars: 5,
                    reviewerName: 'Basile B.',
                    date: 'Il y a 4 jours',
                    comment: `J'ai été conseillé par M Belhadj. Il a été particulièrement à l'écoute de mes besoins et
                     d'excellent conseil. M. Belhajd a su m'orienter, au mieux, vers les produits correspondant à mes
                      attentes, le tout en prenant le soin de m'expliquer avec clarté et précision les différentes
                       possibilités qui m'étaient offertes. Sa patience, sa gentillesse, et son professionalisme sont
                        à souligner. Je recommande.`,
                },
                {
                    stars: 5,
                    reviewerName: 'Marie T.',
                    date: 'Il y a 2 semaines',
                    comment: `Parfait ! Aldric est un conseiller professionel et fort aimable. Il sait écouter, vous
                     conseiller et vous aider dans d'éventuelle démarches. je conseille vivement.`,
                },
            ],
        }

    static SALE_LOIC = {
            expert_id: 117,
            image: 'LOIC_AUGUSTE',
            name: 'Loïc A',
            satisfied_clients: 505,
            clientsReviews: 32,
            seniority: 1,
            reviews: [
                {
                    stars: 5,
                    reviewerName: 'Arnaud H.',
                    date: 'Il y a 6 jours',
                    comment: `Je suis très content d'être passé par Réassurez-moi, qui a su très bien me conseiller
                    grâce au comparateur intelligent et au conseiller que j'ai eu en ligne.`,
                },
                {
                    stars: 5,
                    reviewerName: 'Laetita W.',
                    date: 'Il y a 6 jours',
                    comment: `Je suis passé par Réassurez-moi pour prendre une complémentaire santé spécialisée dans
                    l'hospitalisation en prévision de mon accouchement. Très satisfaite de la simplicité
                    de souscription et de la mutuelle choisie, je recommande.`,
                },
                {
                    stars: 4,
                    reviewerName: 'Jean P.',
                    date: 'Il y a 1 semaine',
                    comment: `Merci à Réassurez-moi. Enfin un vrai courtier en assurance efficace et à la fois compétent
                    . Je remercie tout particulièrement Loïc pour ses compétences, sa réactivité et son amabilité.
                    Je vous le conseille.`,
                },
            ],
        }

    static SALE_HEALTH = {
            expert_id: 'EQUIPE_MS',
            image: 'default',
            name: 'Équipe MS',
            satisfied_clients: 418,
            reviews: [
                {
                    reviewerName: 'Sarah M.',
                    date: 'Il y a 1 semaine',
                    stars: 5,
                    comment: `Très contente de ma souscription. Mes besoins ont été pris en compte et mon conseiller m'a
                 proposé exactement ce que je cherchais. Il a répondu à toutes mes interrogations dans la journée.
                  Très disponible. Je redommande vivement. Encore merci Alban.`,
                },
                {
                    reviewerName: 'Philipe C.',
                    date: 'Il y a 2 semaines',
                    stars: 4,
                    comment: `Un grand merci à Alban pour le temps qu'il a consacré à la mise en place de notre nouvelle
                 mutuelle. De bons conseils à suivre. Ne changez rien. Cordialement.`,
                },
                {
                    reviewerName: 'Valérie N.',
                    date: 'Il y a 4 jours',
                    stars: 5,
                    comment: `Je cherche à revoir mes contrats. J'ai contacté Réassurez-moi car je suis déjà passée par
                 eux l'année dernière. Aujourd'hui, je cherche à revoir ma mutuelle pour l'adapter à ma situation
                  actuelle. Alban m'a très bien conseillée, est à l'écoute des besoins exprimés et m'a fait une
                   proposition très intéressante. Je recommande !`,
                },
            ],
        }

    static reviews: ISale[] = [
        this.SALE_GUILLAUME,
        this.SALE_ALDRIC,
        this.SALE_LOIC,
        this.SALE_HEALTH
    ]

    static ID_MAP_BY_MAIL: {[key: string]: ISale['expert_id']} = {
        "guillaume@reassurez-moi.fr": this.SALE_GUILLAUME.expert_id,
        "aldric@reassurez-moi.fr": this.SALE_ALDRIC.expert_id,
        "loic@reassurez-moi.fr": this.SALE_LOIC.expert_id,
    }
}
