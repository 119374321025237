import { create } from 'zustand'

type Store = {
    toast: { content: string, type: string, duration: number} | null,
    addToast: (content: string, type: string, duration?: number) => void,
}

export const useToastStore = create<Store>((set) => ({
    toast: null,
    addToast: (content, type, duration = 3000) => {
        set({ toast: { content, type, duration } })

        setTimeout(() => {
            set({ toast: null })
        }, duration)
    },
}))